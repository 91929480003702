import React from 'react';
import Image from 'gatsby-image';
import styles from './banner.module.scss';

function FocusBox() {
  return <div className={styles.focus}>
    <div className={styles.focusTitle}>更懂「医疗+互联网」</div>
    <div className={styles.focusDesc}>「移动互联网+医药背景」运营团队，让医疗健康的互联网化运营更专业</div>
    <div className={styles.focusAction}>
      <button>立即咨询</button>
    </div>
  </div>
}

export default function Banner({ images }) {
  return <div className={styles.banner}>
    <Image fixed={images} />
    <FocusBox className={styles.bannerFocus} />
  </div>
}
