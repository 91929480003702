import { graphql } from 'gatsby';
import React from "react";
import Banner from "../components/banner";
import ImageTextSection from '../components/image-text-section';
import IntroSection from "../components/intro-section";
import PageLayout from '../components/page-layout';
import ServiceSection from "../components/service-section";
import populateGatsbyImage from '../utils/populateGatsbyImage';
import SEO from '../components/seo';

const imageTextSectionsData = [
  {
    title: '新媒体代运营',
    desc: '上海葆康医疗管理有限公司为医疗健康类企事业单位提供互联网品牌定位分析、新媒体视觉设计、内容策划运营、健康宣讲活动策划、创意互动H5制作、渠道推广投放、公众号代运营、头条号代运营、视频号代运营、微博代运营、抖音代运营、快手代运营、运营报告等新媒体运营解决方案。',
    features: [
      '根据客户需求，量身定制新媒体运营解决方案',
      '手机+PC+微信生态+其他新媒体平台联合运营',
      '解决五大难题：没定位、没规划、没时间、没效果、没临床思维',
    ],
    image: 'section1',
    backgroundColor: '#F0F0F0',
  }, {
    title: '小程序制作与运营',
    desc: '轻松7步打造专属小程序：需求沟通、方案确认、注册认证账号、程序开发、测试调整、定版上线、后期运营。快速打造你的专属小程序，最大程度利用微信应用场景。',
    features: [
      '深挖微信11亿用户流量，提升品牌知名度',
      '全方位展示医院实力，突出优势专科、特色技术',
      '持续的功能迭代与日常运营，深度连结「医院-医生-患者」',
    ],
    backgroundImage: 'section2',
    textAlign: 'right',
    textBackground: 'rgba(255, 255, 255, 0.8)',
  }, {
    title: 'APP/互联网医院代运营',
    desc: '在互联网浪潮的大趋势下，医疗大健康行业正积极地引入数字化、信息化等技术。然而，“术业有专攻”，合作共赢，合作方能更好地服务于患者，服务于医疗本身。',
    features: [
      'APP预约挂号、在线问诊等的功能设计、开发或对接',
      '互联网医院的咨询与建议、项目对接管理',
      '日常代运营：功能迭代、医患互动、内容策划、活动策划与执行等',
    ],
    backgroundImage: 'section3',
    textBackground: 'rgba(255, 255, 255, 0.8)',
  }, {
    title: '官网制作与运营',
    desc: '官网除了展示医院的品牌形象、医院实力和风采外，还应根据医院需求和实际情况，从用户角度设置内容和营销方式，并定时进行更新和维护，以增强行业竞争力。',
    features: [
      '模板型官网：成本低、制作速度快，但不能定制化，不利于SEO优化',
      '展示型官网：可定制化设计和开发，良好的互动性，丰富的展示功能',
      '响应式官网：采用HTML5+CSS3，兼容PC、手机、iPad等多个平台',
    ],
    image: 'section4',
    textAlign: 'right',
  },/*{
    title: '科研美学服务',
    desc: 'SCI论文插图润色、医学插图插画编辑（论文插图、科研成果汇报插图、医学模型展示、期刊封面等）、科研美学培训等。',
    features: [
      '独家签约多名有丰富临床经验和科研经验的老师',
      '提供SCI论文的插图可视化处理和润色服务',
      '绘制二维插图和三位插图，将研究数据精准可视化表达',
    ],
    image: 'section5',
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },*/ {
    title: '健康测评',
    desc: '秉持“未病先防”、“防大于治”的理念，葆康医疗将在海量的科普养生知识中，为大众提供专业的、系统化的科普内容。研发和开发多套健康测评问卷，帮助大众进行更有效的健康管理。',
    features: [
      '研发和开发健康测评问卷',
      '长达5～15页的测评报告',
      '专业化、系统化健康指导和健康管理',
    ],
    image: 'section6',
    textAlign: 'left'
  }
];

export default function IndexPage({ data }) {
  const { banner } = data;

  return <PageLayout pageKey="/">
    <SEO title="首页" />
    <Banner images={banner.childImageSharp.fixed} />
    <IntroSection />
    {imageTextSectionsData.map((item, i) => <ImageTextSection key={i} {...populateGatsbyImage(data, item, ['image', 'backgroundImage'])} />)}
    <ServiceSection />
  </PageLayout>;
}

export const query = graphql`
  query {
    banner: file(relativePath: {eq: "front-banner.png"}) {
      childImageSharp {
        fixed(width: 1440, height: 800) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    section1: file(relativePath: {eq: "section-1.png"}) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    section2: file(relativePath: {eq: "section-2.jpg"}) {
      childImageSharp {
        fixed(width: 1440, height: 622) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    section3: file(relativePath: {eq: "section-3.jpg"}) {
      childImageSharp {
        fixed(width: 1440, height: 622) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    section4: file(relativePath: {eq: "section-4.png"}) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    section5: file(relativePath: {eq: "section-5.png"}) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    section6: file(relativePath: {eq: "section-6.png"}) {
      childImageSharp {
        fixed(width: 450, height: 450) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
  }
`;
