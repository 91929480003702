import React from 'react';
import iconLock from '../images/icon-lock.svg';
import iconPointer from '../images/icon-pointer.svg';
import iconUser from '../images/icon-user.svg';
import styles from './intro-section.module.scss';

const highlightCardData = [
  {
    title: '懂医疗',
    desc: '80%以上的团队成员有医药背景',
    icon: iconLock,
  }, {
    title: '懂互联网',
    desc: <>63%以上的团队成员有多年互联<br />网工作经验</>,
    icon: iconPointer,
  }, {
    title: '懂用户',
    desc: <>90%的团队成员深耕互联网大<br />健康行业</>,
    icon: iconUser,
  }
];

function HighlightCard({ icon, title, desc }) {
  return <div className={styles.card}>
    <div className={styles.cardIcon}><img src={icon} alt={title} /></div>
    <div className={styles.cardTitle}>{title}</div>
    <div className={styles.cardDesc}>{desc}</div>
  </div>
}

export default function IntroSection() {
  return (
    <div className={styles.section}>
      <div className={styles.desc}>上海葆康医疗管理有限公司致力于为大众提供健康管理服务，为医疗健康类企事业单位提供专业的互联网化运营服务。以帮助医疗健康相关企事业单位构建品牌形象，开拓市场份额，改善患者就医体验，提高医患沟通效率，扩大健康科普宣传力度等。服务主要包括微信公众号运营、微信小程序运营、新媒体运营、新媒体运营方案咨询、小程序开发与代运营、APP/互联网医院代运营、科研美学服务等。</div>
      <div className={styles.highlight}>
        {highlightCardData.map((item, i) => <HighlightCard key={i} {...item} />)}
      </div>
    </div>
  )
}
